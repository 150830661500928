<template>
  <div>
    <table v-if="hasLink" class="table-card">
      <card-component class="has-table has-mobile-sort-spaced header-card">
        <thead class="thead-card">
          <tr class="tr-card">
            <th class="th-card">
              Departamento
            </th>
            <th class="th-card">
              Atendente
            </th>
            <th style="width: 5%"></th>
          </tr>
        </thead>
      </card-component>

      <tbody class="tbody-card">
        <div
          v-for="link in links"
          :key="link.id"
        >
          <contact-attendant-link
            :attendant="getLinkAtt(link.attendant_id)"
            :department="getLinkDept(link.department_id)"
            :link="link"
            :available="{ depts: availableDept, atts: attendants }"
            v-on:editLink="editLink"
            v-on:deleteLink="deleteLink"
          />
        </div>
      </tbody>
    </table>
    <b-button v-if="canLink" @click="addLink" type="is-success" rounded icon-left="wallet-plus" class="add-link">
      Novo Encarteiramento
    </b-button>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent.vue'
import ContactAttendantLink from '@/components/contacts/ContactAttendantLink'

export default {
  name: 'ContactAttendantLinkList',
  props: {
    attendants: {
      type: Array,
      required: true
    },
    departments: {
      type: Array,
      required: true
    },
    contactAttendants: {
      type: Array,
      required: true
    }
  },
  components: {
    CardComponent,
    ContactAttendantLink
  },
  data () {
    return {
      canLink: true,
      hasLink: false,
      links: [],
      availableDept: [],
      availableAtt: [],
      linkAttDept: {},
      linkId: 1,
      aux: {}
    }
  },
  created () {
    this.copyProps()
    this.checkForLinks()
    this.setAvailable()
  },
  watch: {
    contactAttendants (contactAtt, empty) {
      if (empty.length === 0 && this.links.length === 0) {
        this.copyProps()
      }
    },
    links (newLinks, oldLinks) {
      this.checkForLinks()
      this.setAvailable()
    }
  },
  methods: {
    copyProps () {
      this.links = JSON.parse(JSON.stringify(this.contactAttendants))
      this.links.forEach(link => {
        link.id = this.linkId
        this.linkId++
      })
    },
    checkForLinks () {
      if (this.links && this.links.length > 0) {
        this.hasLink = true
      } else {
        this.hasLink = false
      }

      if (this.links.length === this.departments.length && this.departments.length > 0) {
        this.canLink = false
      } else {
        this.canLink = true
      }
    },
    setAvailable () {
      this.availableDept = []
      this.departments.forEach(dept => {
        if (!this.availableDept.find(el => el.id === dept.id)) {
          this.availableDept.push({
            id: dept.id,
            name: dept.name
          })
        }
      })
      this.updateAvailable()
    },
    updateAvailable () {
      this.links.forEach(link => {
        this.availableDept = this.availableDept.filter(dept => dept.id !== link.department_id)
      })
    },
    getLinkAtt (attId) {
      let attendant = this.attendants.filter(att => att?.id === attId)
      attendant = {
        id: attendant[0]?.id,
        name: attendant[0]?.name
      }
      return attendant
    },
    getLinkDept (deptId) {
      let department = this.departments.filter(dept => dept?.id === deptId)
      department = {
        id: department[0]?.id,
        name: department[0]?.name
      }
      return department
    },
    addLink () {
      this.links.push({
        id: this.linkId,
        department_id: null,
        attendant_id: ''
      })
      this.linkId++
      this.$emit('addLinks', this.links)
    },
    editLink (link) {
      this.links.forEach(el => {
        if (el.id === link.id) {
          el.department_id = link.department_id
          el.attendant_id = link.attendant_id
        }
      })
      this.setAvailable()
      this.$emit('addLinks', this.links)
    },
    deleteLink (link) {
      this.links = this.links.filter(el => el.id !== link.id)
      this.setAvailable()
      this.$emit('addLinks', this.links)
    }
  }
}
</script>

<style lang="scss" scoped>

.table-card {
  width: 100%;
  table-layout: fixed;
}

.header-card {
  border: 0px;
  margin-bottom: 15px;
}

.thead-card {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.tr-card {
  width: 100%;
}

.th-card:first-child {
  padding-left: 12px;
  padding-right: 5px;
  font-weight: 600;
  vertical-align: middle;
}

.th-card:nth-child(2) {
  padding-left: 15px;
  padding-right: 5px;
  font-weight: 600;
  vertical-align: middle;
}

.tbody-card {
  width: 100%;
  display: table;
  table-layout: fixed;
}

.add-link {
  float: right;
}

</style>
