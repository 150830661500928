<template>
  <div>
    <card-component title="" class="has-table has-mobile-sort-spaced body-card">
      <tr class="tr-card">
        <td class="td-card">
          <b-field expanded>
            <b-select
              expanded
              placeholder="Selecione um departamento"
              v-model="selectedDept"
              @change.native="editLink"
            >
              <option
                v-for="dept in depts"
                :value="dept.id"
                :key="dept.id"
              >
                {{ dept.name }}
              </option>
            </b-select>
          </b-field>
        </td>

        <td class="td-card">
          <b-field expanded>
            <b-select
              expanded
              placeholder="Selecione um atendente"
              :disabled="!selectedDept"
              v-model="selectedAtt"
              @change.native="editLink"
            >
              <option
                v-for="att in atts"
                :value="att.id"
                :key="att.id"
              >
                {{ att.name }}
              </option>
            </b-select>
          </b-field>
        </td>

        <td>
          <b-button icon-right="delete" class="delete-button" @click="deleteLink" />
        </td>
      </tr>
    </card-component>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent.vue'

export default {
  name: 'ContactAttendantLink',
  props: {
    attendant: {
      type: Object,
      required: true
    },
    department: {
      type: Object,
      required: true
    },
    link: {
      type: Object,
      required: true
    },
    available: {
      type: Object,
      required: true
    }
  },
  components: {
    CardComponent
  },
  data () {
    return {
      selectedDept: this.link.department_id,
      selectedAtt: this.link.attendant_id,
      depts: [],
      atts: [],
      currentLink: JSON.parse(JSON.stringify(this.link))
    }
  },
  created () {
    this.setDeptsAtts()
  },
  watch: {
    available (newAvailable, oldAvailable) {
      this.setDeptsAtts()
    },
    selectedDept (newDept, oldDept) {
      this.currentLink.department_id = newDept
      this.currentLink.attendant_id = ''
      this.editLink()
    },
    selectedAtt (newAtt, oldAtt) {
      this.currentLink.attendant_id = newAtt
      this.editLink()
    }
  },
  methods: {
    setDeptsAtts () {
      this.atts = []
      this.depts = []
      this.depts.push({
        id: this.department.id,
        name: this.department.name
      })
      this.available.depts.forEach(el => {
        this.depts.push({
          id: el.id,
          name: el.name
        })
      })
      this.atts.push({
        id: this.attendant.id,
        name: this.attendant.name
      })
      this.available.atts.forEach(el => {
        if (((this.selectedDept && el.departments.find(dpt => dpt.id === this.selectedDept)) || el.supervisor_all_departments) && !this.atts.find(a => a.id === el.id)) {
          this.atts.push({
            id: el.id,
            name: el.name
          })
        }
      })
    },
    editLink () {
      this.$emit('editLink', this.currentLink)
    },
    deleteLink () {
      this.$emit('deleteLink', this.currentLink)
    }
  }
}
</script>

<style lang="scss" scoped>

.body-card {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 20px;
  margin-top: 10px;
  transition: 0.3s;
  display: table;
  table-layout: fixed;
  width: 100%;
  border: 0px
}

.tr-card {
  width: 100%;
  display: table;
}

.td-card:first-child {
  padding-right: 5px;
  padding-left: 8px;
  width: 50%;
}

.td-card:nth-child(2) {
  padding-right: 5px;
  padding-left: 10px;
  width: 50%;
}

b-select {
  border: 0px;
}

.delete-button {
  color: rgb(255, 70, 70);
  border: 0px;
  font-size: 18px;
}

.delete-button:hover {
  color: red;
}
</style>
